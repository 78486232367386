<template>
  <b-modal
    id="modal-center"
    @change="hideModal"
    centered
    v-model="isAddNewUserModalActive"
    title="Добавить товар"
    @show="showModal"
  >
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Название"
              rules="required"
            >
              <b-form-group label="Название" label-for="new-merch-title-ru">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="name"
                  id="new-merch-title-ru"
                  placeholder="Название"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="12">
            <b-tabs pills nav-class="">
              <b-tab title="RU" active>
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Описание RU"
                      rules="required"
                    >
                      <b-form-group
                        label="Описание RU"
                        label-for="new-merch-desc-ru"
                      >
                        <b-form-textarea
                          id="new-merch-desc-ru"
                          v-model="descriptionRu"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Короткое описание RU"
                      rules="required"
                    >
                      <b-form-group
                        label="Короткое описание RU"
                        label-for="new-merch-sdesc-ru"
                      >
                        <b-form-textarea
                          id="new-merch-sdesc-ru"
                          v-model="shortDescriptionRu"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="UZ">
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Описание UZ"
                      rules="required"
                    >
                      <b-form-group
                        label="Описание UZ"
                        label-for="new-merch-desc-uz"
                      >
                        <b-form-textarea
                          id="new-merch-desc-uz"
                          v-model="descriptionUz"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Короткое описание UZ"
                      rules="required"
                    >
                      <b-form-group
                        label="Короткое описание UZ"
                        label-for="new-merch-sdesc-uz"
                      >
                        <b-form-textarea
                          id="new-merch-sdesc-uz"
                          v-model="shortDescriptionUz"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="En">
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Описание EN"
                      rules="required"
                    >
                      <b-form-group
                        label="Описание EN"
                        label-for="new-merch-desc-en"
                      >
                        <b-form-textarea
                          id="new-merch-desc-en"
                          v-model="descriptionEn"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      name="Короткое описание EN"
                      rules="required"
                    >
                      <b-form-group
                        label="Короткое описание EN"
                        label-for="new-merch-sdesc-en"
                      >
                        <b-form-textarea
                          id="new-merch-sdesc-en"
                          v-model="shortDescriptionEn"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Описание"
                          rows="3"
                        />
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Изображения"
              rules="required"
            >
              <b-form-group label="Изображения" label-for="new-offer-imgs">
                <b-form-file
                  ref="file"
                  multiple
                  id="new-offer-imgs"
                  :state="errors.length > 0 ? false : null"
                  v-model="asd"
                  @change="handleFileUpload($event)"
                  placeholder="Выберите..."
                  accept="image/png, image/jpeg, image/tiff"
                  drop-placeholder="Drop file here..."
                />
                <div>
                  <small class="text-danger" v-if="imageRequired === 1"
                    >Поле Лого поддерживает только файлы в формате .jpg, .png,
                    .tiff</small
                  >
                </div>
                <div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
                <span class="sm-size"
                  >(Поддерживаются файлы в формате .jpg, .png, .tiff)
                </span>
                <!-- <b-card-text class="my-1">
                Выбранный файл: <strong>{{ file ? file.name : "" }}</strong>
              </b-card-text> -->
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Штрих код"
              rules="required"
            >
              <b-form-group label="Штрих код" label-for="new-product-barcode">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="new-product-barcode"
                    v-model="barcode"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Введите штрих код"
                  />
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Цена"
              rules="required"
            >
              <b-form-group label="Цена" label-for="new-product-price">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="new-product-price"
                    v-model="price"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Введите цену"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Сумма скидки"
              :rules="{ max_value: price }"
            >
              <b-form-group
                label="Сумма скидки"
                label-for="new-product-discount-price"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="new-product-discount-price"
                    v-model="discountPrice"
                    type="text"
                    name="max_value_field"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Введите сумму скидки"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Мерчанты"
              rules="required"
            >
              <b-form-group label="Мерчанты" label-for="new-offer-merchants">
                <v-select
                  id="new-offer-merchants"
                  placeholder="Введите название мерчанта"
                  v-model="selectedMerchants"
                  :state="errors.length > 0 ? false : null"
                  label="name"
                  @search="getMerchants"
                  :options="sortedMerchants"
                  multiple
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Теги"
              rules="required"
            >
              <b-form-group label="Теги" label-for="new-offer-tags">
                <v-select
                  id="new-offer-tags"
                  placeholder="Введите название тега"
                  v-model="productTags"
                  label="name"
                  :state="errors.length > 0 ? false : null"
                  taggable
                  push-tags
                  multiple
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Страна производителя"
              rules="required"
            >
              <b-form-group
                label="Страна производителя"
                label-for="new-product-producer-country"
              >
                <v-select
                  id="new-product-producer-country"
                  placeholder="Введите страну"
                  v-model="producerCountry"
                  label="name_ru"
                  :options="countries"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              name="Производитель"
              rules="required"
            >
              <b-form-group
                label="Производитель"
                label-for="new-product-producer"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="new-product-producer"
                    v-model="producer"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Введите производителя"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        type="reset"
        class="res-w-100"
        @click="cancel()"
      >
        Отмена
      </b-button>
      <b-button
        class="res-mt-2 res-w-100"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click.prevent="handleSubmit"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BFormFile,
  BRow,
  BCol,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BButton,
  BTab,
  BTabs,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, max_value, between } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import countries from "@/@fake-db/data/other/countries-ru";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    flatPickr,
    BInputGroup,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BFormFile,
    BRow,
    BCol,
    ToastificationContent,
    BFormTextarea,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewUserModalActive: {
      type: Boolean,
      required: true,
    },
  },
  watch: {},
  computed: {},
  data() {
    return {
      required,
      between,
      max_value,
      countries,

      asd: null,
      name: null,
      barcode: null,
      descriptionRu: null,
      descriptionUz: null,
      descriptionEn: null,
      shortDescriptionRu: null,
      shortDescriptionUz: null,
      shortDescriptionEn: null,
      producer: null,
      producerCountry: null,
      file: null,
      price: null,
      discountPrice: null,
      productImages: [],
      selectedMerchants: null,
      sortedMerchants: null,
      productTags: null,

      imageRequired: 0,
    };
  },
  mounted() {},
  methods: {
    async handleFileUpload(event) {
      this.productImages = [];
      this.asd = event.target.files;
      for (var i = 0; i < this.asd.length; i++) {
        if (
          this.asd[i].type != "image/jpg" &&
          this.asd[i].type != "image/jpeg" &&
          this.asd[i].type != "image/png" &&
          this.asd[i].type != "image/tiff"
        ) {
          this.asd = [];
          this.imageRequired = 1;
          return false;
        }
        this.imageRequired = 2;
        const formData = new FormData();
        formData.append("logo", this.asd[i]);
        const response = await axiosIns.post(
          "image/upload/products",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.productImages.push(response.data.data.imageUrl);
      }
    },
    showModal() {
      if (this.sortedMerchants === null) {
        this.getMerchants();
      } else {
      }
    },
    async getMerchants(search) {
      const response = await axiosIns
        .get(`vendor/all-active`, {
          params: {
            page: 1,
            size: 20,
            field: "name",
            fieldValue: search,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.sortedMerchants = response.data.data.vendors;
    },
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && this.imageRequired === 2) {
          const selectedMerchantsId = this.selectedMerchants.map(function (
            item
          ) {
            return item.id;
          });

          axiosIns
            .post("v1/product/create", {
              name: this.name,
              barcode: this.barcode,
              vendorIdList: selectedMerchantsId,
              descriptionRu: this.descriptionRu,
              descriptionUz: this.descriptionUz,
              descriptionEn: this.descriptionEn,
              shortDescriptionRu: this.shortDescriptionRu,
              shortDescriptionEn: this.shortDescriptionEn,
              shortDescriptionUz: this.shortDescriptionUz,
              price: this.price,
              discountPrice: this.discountPrice,
              productImageList: this.productImages,
              producer: this.producer,
              producerCountry: this.producerCountry.name_ru,
              productTags: this.productTags,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Товар был успешно создан!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.hideModal();
              this.$emit("refetch-data");
              setTimeout(() => {
                this.resetForm();
              }, 200);
            });
        }
      });
    },
    async submitFiles() {
      console.log(this.$refs.file.files);
      for (var i = 0; i < this.$refs.file.files.length; i++) {
        /* if (
          this.$refs.file.files[i].type != "image/jpg" &&
          this.$refs.file.files[i].type != "image/jpeg" &&
          this.$refs.file.files[i].type != "image/png" &&
          this.$refs.file.files[i].type != "image/tiff"
        ) {
          this.this.$refs.file.files = "";
          this.imageRequired = 1;
          return false;
        }
        this.imageRequired = 2; */
        const formData = new FormData();
        formData.append("logo", this.$refs.file.files[i]);
        const response = await axiosIns.post(
          "image/upload/products",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        this.productImages.push(response.data.data.imageUrl);
      }
    },
    hideModal() {
      this.$emit("hideModal");
    },
    resetForm() {
      this.name = null;
      this.barcode = null;
      this.descriptionRu = null;
      this.descriptionUz = null;
      this.descriptionEn = null;
      this.shortDescriptionRu = null;
      this.shortDescriptionUz = null;
      this.shortDescriptionEn = null;
      this.producer = null;
      this.producerCountry = null;
      this.file = null;
      this.price = null;
      this.discountPrice = null;
      this.productImages = [];
      this.selectedMerchants = null;
      this.sortedMerchants = null;
      this.productTags = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
