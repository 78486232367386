var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Добавить товар"},on:{"change":_vm.hideModal,"show":_vm.showModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"res-w-100",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){return cancel()}}},[_vm._v(" Отмена ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Добавить")])])]}}]),model:{value:(_vm.isAddNewUserModalActive),callback:function ($$v) {_vm.isAddNewUserModalActive=$$v},expression:"isAddNewUserModalActive"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"new-merch-title-ru"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-title-ru","placeholder":"Название"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-tabs',{attrs:{"pills":"","nav-class":""}},[_c('b-tab',{attrs:{"title":"RU","active":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание RU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание RU","label-for":"new-merch-desc-ru"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-ru","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionRu),callback:function ($$v) {_vm.descriptionRu=$$v},expression:"descriptionRu"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Короткое описание RU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Короткое описание RU","label-for":"new-merch-sdesc-ru"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-sdesc-ru","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.shortDescriptionRu),callback:function ($$v) {_vm.shortDescriptionRu=$$v},expression:"shortDescriptionRu"}})],1)]}}])})],1)],1)],1),_c('b-tab',{attrs:{"title":"UZ"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание UZ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание UZ","label-for":"new-merch-desc-uz"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-uz","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionUz),callback:function ($$v) {_vm.descriptionUz=$$v},expression:"descriptionUz"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Короткое описание UZ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Короткое описание UZ","label-for":"new-merch-sdesc-uz"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-sdesc-uz","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.shortDescriptionUz),callback:function ($$v) {_vm.shortDescriptionUz=$$v},expression:"shortDescriptionUz"}})],1)]}}])})],1)],1)],1),_c('b-tab',{attrs:{"title":"En"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание EN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание EN","label-for":"new-merch-desc-en"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-en","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionEn),callback:function ($$v) {_vm.descriptionEn=$$v},expression:"descriptionEn"}})],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Короткое описание EN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Короткое описание EN","label-for":"new-merch-sdesc-en"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-sdesc-en","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.shortDescriptionEn),callback:function ($$v) {_vm.shortDescriptionEn=$$v},expression:"shortDescriptionEn"}})],1)]}}])})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Изображения","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Изображения","label-for":"new-offer-imgs"}},[_c('b-form-file',{ref:"file",attrs:{"multiple":"","id":"new-offer-imgs","state":errors.length > 0 ? false : null,"placeholder":"Выберите...","accept":"image/png, image/jpeg, image/tiff","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUpload($event)}},model:{value:(_vm.asd),callback:function ($$v) {_vm.asd=$$v},expression:"asd"}}),_c('div',[(_vm.imageRequired === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Штрих код","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Штрих код","label-for":"new-product-barcode"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"new-product-barcode","state":errors.length > 0 ? false : null,"placeholder":"Введите штрих код"},model:{value:(_vm.barcode),callback:function ($$v) {_vm.barcode=$$v},expression:"barcode"}})],1)],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Цена","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Цена","label-for":"new-product-price"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"new-product-price","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Введите цену"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Сумма скидки","rules":{ max_value: _vm.price }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Сумма скидки","label-for":"new-product-discount-price"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"new-product-discount-price","type":"text","name":"max_value_field","state":errors.length > 0 ? false : null,"placeholder":"Введите сумму скидки"},model:{value:(_vm.discountPrice),callback:function ($$v) {_vm.discountPrice=$$v},expression:"discountPrice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Мерчанты","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Мерчанты","label-for":"new-offer-merchants"}},[_c('v-select',{attrs:{"id":"new-offer-merchants","placeholder":"Введите название мерчанта","state":errors.length > 0 ? false : null,"label":"name","options":_vm.sortedMerchants,"multiple":""},on:{"search":_vm.getMerchants},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.selectedMerchants),callback:function ($$v) {_vm.selectedMerchants=$$v},expression:"selectedMerchants"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Теги","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Теги","label-for":"new-offer-tags"}},[_c('v-select',{attrs:{"id":"new-offer-tags","placeholder":"Введите название тега","label":"name","state":errors.length > 0 ? false : null,"taggable":"","push-tags":"","multiple":""},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.productTags),callback:function ($$v) {_vm.productTags=$$v},expression:"productTags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Страна производителя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Страна производителя","label-for":"new-product-producer-country"}},[_c('v-select',{attrs:{"id":"new-product-producer-country","placeholder":"Введите страну","label":"name_ru","options":_vm.countries,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.producerCountry),callback:function ($$v) {_vm.producerCountry=$$v},expression:"producerCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Производитель","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Производитель","label-for":"new-product-producer"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"new-product-producer","state":errors.length > 0 ? false : null,"placeholder":"Введите производителя"},model:{value:(_vm.producer),callback:function ($$v) {_vm.producer=$$v},expression:"producer"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }