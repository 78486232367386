<template>
  <b-row>
    <ProductsListAddNew
      @refetch-data="refetchData"
      @hideModal="isAddNewUserModalActive = false"
      :isAddNewUserModalActive="isAddNewUserModalActive"
    />
    <b-col md="12">
      <h1 class="mb-2">Товары</h1>
    </b-col>
    <b-col md="12">
      <!-- <ProductsListFilters
        @filterByTag="filterByTag"
        @filterByCategory="filterByCategory"
        @filterByStatus="filterByStatus"
        @filterByDateFrom="filterByDateFrom"
        @filterByDateTo="filterByDateTo"
      /> -->
      <b-card no-body class="mb-0">
        <div class="px-2 py-1">
          <b-row>
            <b-col md="12">
              <div class="d-flex res-flex-column justify-content-between">
                <div class="d-flex align-items-center">
                  <span class="text-nowrap">Отображать по:</span>
                  <v-select
                    v-model="pageLength"
                    :options="pages"
                    :clearable="false"
                    @input="handlePageLengthChange"
                    class="per-page-selector d-inline-block mx-50"
                  />
                </div>

                <div
                  class="
                    d-flex
                    res-flex-column res-users-list-search
                    align-items-center
                  "
                >
                  <div class="mr-2 res-m-0 res-w-100">
                    <!-- basic search -->
                    <b-input-group
                      class="input-group-merge res-w-100 mr-2 res-mt-2"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        class="res-w-100"
                        @input="handleSearch"
                        v-model="searchTerm"
                        placeholder="Поиск"
                      />
                    </b-input-group>
                  </div>

                  <b-button
                    class="res-mt-2 res-w-100"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="isAddNewUserModalActive = true"
                  >
                    <span class="text-nowrap">Добавить товар</span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <vue-good-table
          class="position-relative"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="false"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: name -->
            <span v-if="props.column.field === 'name'" class="text-nowrap">
              <router-link
                :to="{
                  name: 'product-edit',
                  params: { id: props.row.id },
                }"
              >
                <span class="text-nowrap font-weight-bold text-nowrap">{{
                  props.row.name
                }}</span>
              </router-link>
            </span>

            <!-- Column: productTags -->
            <span v-else-if="props.column.field === 'productTags'">
              <div
                class="d-flex"
                v-if="props.row.productTags && props.row.productTags.length > 0"
              >
                <b-badge
                  variant="primary"
                  class="mr-1"
                  v-for="(item, i) in props.row.productTags"
                  :key="i"
                >
                  {{ item }}
                </b-badge>
              </div>
              <span v-else> Пусто </span>
            </span>

            <!-- Column: vendorsList -->
            <span v-else-if="props.column.field === 'vendorsList'">
              <div class="d-flex">
                <b-badge
                  variant="primary"
                  class="mr-1"
                  v-for="(item, i) in props.row.vendorsList"
                  :key="item.i"
                >
                  {{ item }}
                </b-badge>
              </div>
            </span>

            <!-- Column: dateCreated -->
            <span
              v-else-if="props.column.field === 'dateCreated'"
              class="text-nowrap"
            >
              <span>
                {{ props.formattedRow.dateCreated | formatDate }}
              </span>
              <span v-if="props.row.dateCreated == null"> Не указано </span>
            </span>

            <!-- Column: dateModified -->
            <span
              v-else-if="props.column.field === 'dateModified'"
              class="text-nowrap"
            >
              <span>
                {{ props.formattedRow.dateModified | formatDate }}
              </span>
              <span v-if="props.row.dateModified == null"> Не указано </span>
            </span>

            <!-- Column: finishDate -->
            <span
              v-else-if="props.column.field === 'daysCountTillTheEnd'"
              class="text-nowrap"
            >
              <span> {{ props.formattedRow.daysCountTillTheEnd }} дней </span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'product-edit',
                      params: { id: props.row.id },
                    }"
                  >
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Изменить</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="deleteProduct(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Удалить</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom">
            <div
              class="d-flex justify-content-between px-2 pb-2 pt-1 flex-wrap"
            >
              <!-- page totalCount -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-muted">Общее кол-во: {{ totalCount }}</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalCount"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import ProductsListAddNew from "./ProductsListAddNew.vue";
/* import ProductsListFilters from "./ProductsListFilters.vue"; */
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    ProductsListAddNew,
    /* ProductsListFilters, */
    ToastificationContent,
    Ripple,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    BCard,
    BRow,
    BCol,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
  },
  directives: {
    Ripple,
  },
  computed: {},
  data() {
    return {
      isAddNewUserModalActive: false,
      pageLength: 10,
      currentPage: 1,
      totalCount: null,
      sortField: null,
      field: null,
      fieldValue: null,
      pages: ["5", "10", "15", "20"],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Цена",
          field: "price",
        },
        {
          label: "Штрих код",
          field: "barcode",
        },
        {
          label: "Скидка",
          field: "discountPrice",
        },
        {
          label: "Производитель",
          field: "producer",
        },
        {
          label: "Страна произв.",
          field: "producerCountry",
        },
        {
          label: "Теги",
          field: "productTags",
        },
        {
          label: "Мерчанты",
          field: "vendorsList",
        },
        {
          label: "Дата создания",
          field: "dateCreated",
        },
        {
          label: "Дата редактирования",
          field: "dateModified",
        },
        {
          label: "Действие",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      selectedProduct: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await axiosIns
        .get(`v1/product/getAll`, {
          params: {
            page: this.currentPage,
            size: this.pageLength,
            sort: this.sortField,
            field: this.field,
            fieldValue: this.fieldValue,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.rows = response.data.data.products;
      this.totalCount = response.data.data.totalCount;
    },
    async refetchData() {
      this.sort = "";
      this.field = "";
      this.fieldValue = "";
      this.getData();
    },
    handleSearch(searching) {
      this.field = "name";
      this.fieldValue = searching;
      this.getData();
    },
    async handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    async handlePageLengthChange(active) {
      this.pageLength = active;
      this.getData();
    },
    onSortChange(params) {
      if (params[0].field !== "action") {
        this.sortField = params[0].field + "," + params[0].type;
        this.getData();
      }
    },
    filterByTag(tag) {
      this.field = "businessType";
      this.fieldValue = tag.businessType;
      this.getData();
    },
    filterByCategory(category) {
      this.field = "vendorCategoryList";
      this.fieldValue = category.titleRu;
      this.getData();
    },
    filterByStatus(status) {
      this.field = "vendorState";
      this.fieldValue = status;
      this.getData();
    },
    filterByDateFrom(date) {
      this.dateFrom = date + " 00:00:00";
      if (this.dateTo.length) {
        this.field = "dateCreated";
        this.fieldValue = this.dateFrom + ";" + this.dateTo;
        this.getData();
      }
      /* this.getData(); */
    },
    filterByDateTo(date) {
      this.dateTo = date + " 59:00:00";
      if (this.dateFrom.length) {
        this.field = "dateCreated";
        this.fieldValue = this.dateFrom + ";" + this.dateTo;
        this.getData();
      }
      /* this.getData(); */
    },

    async deleteProduct(id) {
      this.selectedProduct = id;
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить?", {
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: "Удалить",
          cancelTitle: "Отмена",
        })
        .then((value) => {
          if (value === true) {
            axiosIns
              .delete("v1/product/delete", {
                params: {
                  productId: this.selectedProduct,
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: `Успешно`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Товар был успешно удален!`,
                      },
                    },
                    {
                      position: "top-right",
                    }
                  );
                  this.getData();
                }
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Ошибка`,
                      icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                      variant: "danger",
                      text: error.response.data.errorMessage,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
              });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.per-page-selector {
  min-width: 77px;
}
</style>
